import axios from 'axios';
import { Toast } from 'vant';

const requestSec = axios.create({
  baseURL: process.env.NODE_ENV === 'development'?process.env.VUE_APP_BASE_OPENID:'/weixin-abutment/index.php',
  //withCredentials: true,
  timeout: 10000, //超时
});

requestSec.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
requestSec.interceptors.response.use(
  function (response) {
    let { data, code, message } = response.data;
    if (code === 200) {
      return response.data;
    } else {
      Toast(message);
      return Promise.reject(code, message);
    }
  },
   function (error) {
    Toast('请求出错');
    return Promise.reject(error);
  }
);
// 将service 导出
export default requestSec;
