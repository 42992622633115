import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '卡儿酷',
    },
  },
  {
    path: '/advice',
    name: 'advice',
    component: () => import('../views/advice.vue'),
    meta: {
      title: '建议管理',
      keepAlive: true,
    },
  },
  {
    path: '/manager',
    name: 'manager',
    component: () => import('../views/manager.vue'),
    meta: {
      title: '管理员',
    },
  },
  {
    path: '/add-manager/:id?',
    name: 'add-manager',
    component: () => import('../views/add-manager.vue'),
  },
  {
    path: '/department',
    name: 'department',
    component: () => import('../views/department.vue'),
    meta: {
      title: '部门结构',
    },
  },
  {
    path: '/instructions',
    name: 'instructions',
    component: () => import('../views/instructions.vue'),
    meta: {
      title: '提建议',
    },
  },
  {
    path: '/registered-users',
    name: 'registered-users',
    component: () => import('../views/registered-users.vue'),
    meta: {
      title: '提建议',
    },
  },
  {
    path: '/give-advice',
    name: 'give-advice',
    component: () => import('../views/give-advice.vue'),
    meta: {
      title: '提建议',
    },
  },
];

const router = new VueRouter({
    routes,
});

export default router;
