import Vue from 'vue';
import {
  Pagination,
  Button,
  Tab,
  Tabs,
  Popover,
  Field,
  Form,
  Icon,
  Overlay,
  Cell,
  CellGroup,
  Dialog,
  Checkbox,
  CheckboxGroup,
  Picker,
  Popup,
  Sticky,
  List,
  Search,
  Loading
} from 'vant';
Vue.use(Loading);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Popover);
Vue.use(Form);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Overlay);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Sticky);
Vue.use(List);
Vue.use(Search);




