import Vue from "vue";
import Vuex from "vuex";
import {userInfo} from '@/api'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    roles: null,
  },
  mutations: {
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
  },
  getters: {
    roles: state => state.roles,
  },
  actions: {
    roles({ commit }, roles) {
      commit('SET_ROLES', roles);
    },

    async self_info({ commit }) {
      try {
        const { code, msg, data } = await userInfo({ ID: localStorage.getItem('userid') });
        if (code === 200) {
          commit('SET_ROLES', data.roles);
          return Promise.resolve({ code, msg }); // 外抛数据
        } else {
          return Promise.reject(new Error(msg));
        }
      } catch (code) {
         if (code === 400) {
            localStorage.clear();
         }
        return Promise.reject({ code });
      }
    },
  },
});
