import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import '@/utils/vant';
import './assets/styles/reset.scss';
import './assets/styles/border.css';
import 'element-ui/lib/theme-chalk/index.css';
import { Autocomplete, Select, Option } from 'element-ui';
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(Autocomplete.name, Autocomplete);
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  const roles = store.getters.roles;
  if (localStorage.getItem('userid')) {
    if (roles === null) {
      await store.dispatch('self_info');
      next({ ...to, replace: true });
    }
    next();
  } else {
    next();
  }
});
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
