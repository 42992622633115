import request from '../utils/request';
import requestSec from '../utils/requestSec';
export function openid(data) {
  return requestSec({
    url: '/openid',
    method: 'get',
    params: data,
  });
}
// 用户注册
export function userRegister(data) {
  return request({
    url: '/user/register?',
    method: 'post',
    data,
  });
}
// 用户详情
export function userInfo(data) {
  return request({
    url: '/user/info?',
    method: 'post',
    data,
  });
}
export function login(data) {
  return request({
    url: '/user/login?',
    method: 'post',
    data,
  });
}
// 获取管理员列表
export function adminList(data) {
  return request({
    url: '/user/admin/list?',
    method: 'post',
    data,
  });
}
// 删除管理员接口
export function revokeAdmin(data) {
  return request({
    url: '/user/revokeAdmin?',
    method: 'post',
    data,
  });
}
// 部门列表
export function departmentList(data) {
  return request({
    url: '/department/list?',
    method: 'post',
    data,
  });
}
// 删除部门
export function departmentDissolve(data) {
  return request({
    url: '/department/dissolve?',
    method: 'post',
    data,
  });
}
// 添加/編輯部门
export function departmentOrganize(data) {
  return request({
    url: '/department/organize?',
    method: 'post',
    data,
  });
}
// 添加/編輯部门
export function departmentEdit(data) {
  return request({
    url: '/department/edit?',
    method: 'post',
    data,
  });
}

// 部门排序
export function departmentAdjustingOrder(data) {
  return request({
    url: '/department/adjustingOrder?',
    method: 'post',
    data,
  });
}
//编辑管理员
export function editInfo(data) {
  return request({
    url: '/user/admin/editInfo?',
    method: 'post',
    data,
  });
}

// 用户详情
export function info(data) {
  return request({
    url:  '/user/info?',
    method: 'post',
    data,
  });
}

// 关键词搜索用户
export function sreachUser(data) {
  return request({
    url: '/user/sreachUser?',
    method: 'post',
    data,
  });
}

// 提议列表
export function proposalList(data) {
  return request({
    url: '/proposal/list?',
    method: 'post',
    data,
  });
}

// 提交建议
export function proposalPropose(data) {
  return request({
    url: '/proposal/propose?',
    method: 'post',
    data,
  });
}
// 删提议
export function proposalDeleteInvalid(data) {
  return request({
    url: '/proposal/deleteInvalid?',
    method: 'post',
    data,
  });
}
//删回复
export function proposalReplyRevoke(data) {
  return request({
    url: '/proposal/reply/revoke?',
    method: 'post',
    data,
  });
}

//回复提议
export function proposalReply(data) {
  return request({
    url: '/proposal/reply?',
    method: 'post',
    data,
  });
}

// 奖励提议接口
export function proposalReplyReward(data) {
  return request({
    url: '/proposal/reward?',
    method: 'post',
    data,
  });
}

// 标记接口

export function proposalMark(data) {
  return request({
    url: '/proposal/mark?',
    method: 'post',
    data,
  });
}
